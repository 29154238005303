import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectFeedback } from './shopsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { EditLinkedUsers } from '../../app/components/EditLinkedUsers';
import { EditLinkedFields } from '../../app/components/EditLinkedFields';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { create } from './actions';
import {defaultShop} from './util';
import {cloneDeep} from 'lodash';
import {useMap} from 'ahooks';

export function AddShop() {
  const dispatch = useAppDispatch();
  const feedback = useAppSelector(selectFeedback);
  const navigate = useNavigate();

  const [localeShop, setLocaleShop] = useState<Shop>(defaultShop());
  const [localeLinkedUsers, setLocaleLinkedUsers] = useState<User[]>([]);
  const [localeLinkedFields, setLocaleLinkedFields] = useState<FieldDefinition[]>([]);
  // Map of FieldFragmentVisibility.FieldFragment.id as key and FieldFragmentVisibility.visibility as value
  const [localeFieldFragmentVisibilityMap, { set: setLocaleFieldFragmentVisibility }] = useMap<string, VisibilityType>([]);

  const updateShop = useCallback((key: string, value: string) => {
    setLocaleShop({ ...localeShop, [key]: value });
  }, [localeShop]);

  const linkUsers = useCallback((users: User[], linked: boolean) => {
    // Add user to linked users
    if (linked) {
      setLocaleLinkedUsers([...localeLinkedUsers, ...users])
    } else {
      // Remove user from linked users
      const newList = localeLinkedUsers.reduce((acc, curr) => {
        if (users.find(user => user.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as User[]);
      setLocaleLinkedUsers(newList);
    }
  }, [localeLinkedUsers]);

  const linkField = useCallback((field: FieldDefinition, linked: boolean) => {
    // Add field to linked fields
    if (linked) {
      if (localeLinkedFields.find(curr => curr.id === field.id)) {
        return;
      }
      setLocaleLinkedFields([...localeLinkedFields, field])
    } else {
      // Remove field from linked fields
      setLocaleLinkedFields(localeLinkedFields.filter(curr => curr.id !== field.id));
    }
  }, [localeLinkedFields]);

  const fieldFragmentVisibilityChange = useCallback((fieldFragment: FieldFragment, visibility: VisibilityType) => {
    setLocaleFieldFragmentVisibility(fieldFragment.id, visibility);
  }, [setLocaleFieldFragmentVisibility]);

  const onSubmit = useCallback(() => {
    const cb = () => {
      navigate('/shops');
    };
    const newShop = cloneDeep(localeShop);
    newShop.users = localeLinkedUsers;
    newShop.fieldDefinitions = localeLinkedFields;
    newShop.fieldFragmentVisibility = Array.from(localeFieldFragmentVisibilityMap.keys()).map(key => ({
      fieldFragment: {id: key},
      visibility: localeFieldFragmentVisibilityMap.get(key),
    })) as FieldFragmentVisibility[];
    dispatch(create({ shop: newShop, cb }));
  }, [dispatch, navigate, localeShop, localeLinkedUsers, localeLinkedFields, localeFieldFragmentVisibilityMap]);

  return (
    <>
      <ToolBar title='shops.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <Form onSubmit={onSubmit} shop={localeShop} onChange={updateShop}>
          <EditLinkedFields
          onChange={linkField}
          assigendFields={feedback.isWaiting ? null : localeLinkedFields}
          onVisibilityChange={fieldFragmentVisibilityChange}
          fieldFragmentVisibilityMap={localeFieldFragmentVisibilityMap}
          />
          </Form>
        <EditLinkedUsers onChange={linkUsers} assigendUsers={localeLinkedUsers} />
      </div>
    </>
  )
}
