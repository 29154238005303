import { useTranslation } from 'react-i18next';
import { formatAmount, valueSign, entityName } from '../utils';
import { useAppSelector } from '../../../app/hooks';
import {selectBalanceSummary} from '../entriesSlice';


interface Props {}
const dumpOperationFieldsToString = (values:any[][]|undefined):string[][] => {
  if (typeof values === 'undefined') {
    return [['']];
  }
  return values.reduce((acc, curr) => {
    const [_entityKey, entityOperation, entitySlug, _entityType, entityValue] = curr;
    acc.push([`${entitySlug}`, `${entityOperation}`, `${entityValue === false ? '-' : entityValue}`]);
    return acc;
  }, [] as string[])
  .sort((a, b) => {
    const nameA = a[0].toUpperCase();
    const nameB = b[0].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

interface DumpOperationFieldsProps {
  rows: any[][]|undefined;
}

const DumpOperationFieldsRow = (props:{items: string[]}) => {
  return (
    <>
    {props.items.map((item:string, idx:number) => <div key={idx}>{item}</div>)}
    </>
  );
}

const DumpOperationFields = (props:DumpOperationFieldsProps ) => {
  const rows = dumpOperationFieldsToString(props.rows);
  return <div className='grid grid-cols-3 gap-y-1' >{rows.map((row, idx:number) => (
    <DumpOperationFieldsRow key={idx} items={row} />
  ))}
</div>
}


type VerboseLevel = "none" | "low" | "high";
export const Balance = (_props: Props ) => {
  const balanceSummary = useAppSelector(selectBalanceSummary);
  const { i18n } = useTranslation();
  let verboseLevel:VerboseLevel = "high";

  return (
    <div>
    <div className="mt-6">
      {balanceSummary && balanceSummary.map(item =>
        <div key={item.key} className='mb-2'>
          <div className="flex justify-between text-base">
            <span className='mb-0'>
              {entityName(item.balance, i18n.language as keyof LanguageProperties)}
            </span>
            <div className='whitespace-nowrap'
            data-balance-value={item.balance_calc_sum}
            >{`
              ${valueSign(item.balance_calc_sum)}
                ${formatAmount(Math.abs(item.balance_calc_sum))}
              `} &euro;
            </div>
          </div>

          {(verboseLevel === "low") && (
          <div className="flex justify-end text-xs mb-0 bg-neutral-200 verbose">
            <div>{(item.dump?.operationFieldValuesRaw || [] ).join(" ")}</div>
          </div>
          )}

          {(verboseLevel === "high") && (
          <div className="flex justify-start text-xs mb-2 bg-neutral-300 verbose">
            <DumpOperationFields rows={item.dump?.operationFields} />
          </div>
          )}
        </div>
      )}
    </div>
</div>
  );
};

