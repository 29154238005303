import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectFeedback } from './balancesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Feedback } from './components/Feedback';
import { create } from './actions';
import { basePath } from './routes';
import { findAll as findAllCustomFields } from '../custom-fields/actions';
import {defaultBalance, reduceBalance} from './utils';
import {FetchAllBalancesEffect} from './effects';
import {FetchAllNamedFieldsEffect} from '../named-fields/effects';

export function AddBalance() {
  const dispatch = useAppDispatch();
  const feedback = useAppSelector(selectFeedback);
  const navigate = useNavigate();

  const [localeBalance, setLocaleBalance] = useState<Balance>(defaultBalance());

  useEffect(() => {
    // Fetch fieldDefinitions for BalanceFieldOperations
    dispatch(findAllCustomFields());
  }, [dispatch]);

  const updateBalance = useCallback((key: keyof Balance , value: string, language: keyof LanguageProperties = 'en') => {
    return setLocaleBalance(
      {...localeBalance, [key]: {...localeBalance[key] , [language]: value }}
    );
  }, [localeBalance]);

  // Make sure sub components have a list of all balances.
  FetchAllBalancesEffect();
  // Make sure sub components have a list of all namedFields.
  FetchAllNamedFieldsEffect();

  const updateFieldOperations = useCallback((key: keyof Balance, value: BalanceOperationBase[]) => {
    if (!localeBalance) { return }
    return setLocaleBalance(
      {...localeBalance, [key]: value }
    );
  }, [localeBalance]);

  const onSubmit = useCallback(() => {
    if (!localeBalance) { return }
    const run = async() => {
      try {
        const data = reduceBalance(localeBalance);
        if (data) {
          const result = await dispatch(create(data));
          if ('error' in result) {
            return false;
          }
        }
        navigate(basePath);
      } catch (err) {}
    };
    run();
  }, [dispatch, navigate, localeBalance]);

  return (
    <>
      <ToolBar title='balances.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        {localeBalance && <Form
          onSubmit={onSubmit}
          balance={localeBalance}
          onChange={updateBalance}
          onOperationChange={updateFieldOperations}
          /> }
        <Feedback />
      </div>
    </>
  )
}
