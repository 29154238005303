import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findAll, findOne, create, update, findArchived } from './actions';

interface shopsState {
  error: boolean | null;
  isWaiting: boolean;
  items: Shop[] | null;
  archived: Shop[] | null;
  current: Shop | null;
  message: string;
}

const initialState: shopsState = {
  error: false,
  isWaiting: false,
  items: null,
  archived: null,
  current: null,
  message: '',
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL SHOPS
    .addCase(findAll.pending, (state) => {
      pending(state);
    })
    .addCase(findAll.fulfilled, (state, action) => {
      fulfilled(state);
      state.items = action.payload.items;
      state.current = null;
    })
    .addCase(findAll.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.items = null;
    })
    // GET SINGLE SHOP
    .addCase(findOne.pending, (state) => {
      pending(state);
      state.current = null;
    })
    .addCase(findOne.fulfilled, (state, action) => {
      fulfilled(state);
      state.current = action.payload;
    })
    .addCase(findOne.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // CREATE SHOP
    .addCase(create.pending, (state) => {
      pending(state, 'Saving shop');
      state.current = null;
    })
    .addCase(create.fulfilled, (state, action) => {
      fulfilled(state, 'Shop saved')
      state.current = action.payload;
    })
    .addCase(create.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // UPDATE SHOP
    .addCase(update.pending, (state) => {
      pending(state, 'Saving shop');
    })
    .addCase(update.fulfilled, (state, action) => {
      fulfilled(state, 'Shop saved');
      state.current = action.payload;
    })
    .addCase(update.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // ARCHIVED SHOPS
    .addCase(findArchived.pending, (state) => {
      pending(state);
    })
    .addCase(findArchived.fulfilled, (state, action) => {
      fulfilled(state);
      state.archived = action.payload;
    })
    .addCase(findArchived.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    .addDefaultCase(() => { }),
});

// SELECTORS
export const selectShops = (state: RootState) => state.shops.items;
export const selectShop = (state: RootState) => state.shops.current;
export const selectArchived = (state: RootState) => state.shops.archived;
export const selectFeedback = (state: RootState): Feedback => ({
  isWaiting: state.shops.isWaiting,
  message: Array.isArray(state.shops.message) ? state.shops.message : [state.shops.message],
  error: state.shops.error,
});

export default shopsSlice.reducer;
