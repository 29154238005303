import { useCallback, useEffect, useState, useMemo } from 'react';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { Trans } from 'react-i18next';
import isISO8601 from 'validator/lib/isISO8601';
import { ToolBar } from '../../app/components/ToolBar';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Tag } from '../../app/components/Tag';
import { Feedback } from './components/Feedback';
import { Actions } from './components/ToolBarActions';
import {findOnDay } from './actions';
import checksService from './checksService';
import {
  selectFeedback,
  selectShopEntries,
  selectAvailableColumns,
  selectAvailableFilters,
} from './checksSlice';
import { asEntryDate, getName, formatAmount, currency } from '../entries/utils';
import { useLocalStorageState } from 'ahooks';
import { Config } from './components/Config';
import { Filters } from './components/Filters';
import { DownloadSelector } from './components/DownloadSelector';
import { AlignType } from 'rc-table/lib/interface';

function CurrencyColumn (value:string|number) {
  return (
    <div className="whitespace-nowrap">{currency(formatAmount(value))}</div>
  );
};

function ShopColumn (value:string, data:{id:string}) {
  return (
    <div className="font-bold">
      <Link to={`/entries/shop/${data.id}`}>{value}</Link>
    </div>
  );
};

function TableSummary(selectedColumns:string[], availableColumns:ColumnDescription[], fieldDefinitionTotalSummary:Record<string,number>) {
  const columns = useMemo(() => {
    // If no columns are selected then all columns are selected
    if (selectedColumns.length === 0) {
      return availableColumns.map(item => item.key);
    }
    // Only show summary for selected columns
    return availableColumns.filter(item => selectedColumns.indexOf(item.key) !== -1).map(item => item.key)
  }, [availableColumns, selectedColumns]);

  if (fieldDefinitionTotalSummary === null){
    return null;
  }
  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="bg-neutral-700 text-white font-bold" >
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        {columns && columns.map((item, idx) =>
          <Table.Summary.Cell key={item} index={idx+1} align="right">
          <span className="whitespace-nowrap">{currency(formatAmount(fieldDefinitionTotalSummary[item]))}</span>
          </Table.Summary.Cell>
        )}
      </Table.Summary.Row>
    </Table.Summary>
  );
}

type SidePanel = "none" | "filter" | "config" | "download";

export function Overview() {
  const dispatch = useAppDispatch();
  const shopEntries = useAppSelector(selectShopEntries);
  const feedback = useAppSelector(selectFeedback);
  const availableColumns = useAppSelector(selectAvailableColumns);
  const availableFilters = useAppSelector(selectAvailableFilters);
  const [fieldDefinitionTotalSummary, setFieldDefinitionTotalSummary] = useState<Record<string, number>>({});
  const [entryDate, setEntryDate] = useState<string>(asEntryDate(new Date()));
  const [selectedColumns, setSelectedColumns] = useLocalStorageState('check-selected-columns', {
    defaultValue: [] as string[],
  });
  const [selectedFilters, setSelectedFilters] = useLocalStorageState('check-selected-filters', {
    defaultValue: [] as string[],
  });
  const [panel, setPanel] = useState<SidePanel>('none');

  const tableColumns = useMemo(() =>{
    if (!availableColumns) { return []; }
    const shopColumn = {
      key: 'name',
      dataIndex: 'name',
      title: (<Trans i18nKey="shops" />),
      align: 'left' as AlignType,
      fixed: true,
      width: 280,
      render: ShopColumn,
    };
    const allTableColumns = [
      shopColumn,
      ...availableColumns.map((item) => ({
        ...item,
        dataIndex: item.key,
        title: (<Trans i18nKey={getName(item)} />),
        align: 'right' as AlignType,
        width: 160,
        render: CurrencyColumn,
      })),
    ];
    if (selectedColumns.length === 0) {
      return allTableColumns;
    }
    return allTableColumns.filter(item => item.key === shopColumn.key || selectedColumns.indexOf(item.key) !== -1);
  }, [availableColumns, selectedColumns]);

  // Fetch Entry and Shop (will also run on every datepicker date change)
  useEffect(() => {
    dispatch(findOnDay({ date: entryDate }));
  }, [dispatch, entryDate]);

  // Filter shopEntries based on selected filters
  const dataSource = useMemo(() => {
    if (selectedFilters.length === 0 || availableFilters.length === 0) {
      return shopEntries;
    }
    // Get shops of the selected users
    const shopIds = uniq(availableFilters
    .filter(item => selectedFilters.indexOf(item.key) !== -1)
    .map(item => item.data || [])
    .flat());
    //
    return shopEntries.filter(item => shopIds.indexOf(item.id) !== -1);
  }, [shopEntries, selectedFilters, availableFilters]);

  const hasFilters = useMemo(() => availableFilters.length > 0, [availableFilters]);

  useEffect(() => {
    const summary:Record<string, number> = {};
    dataSource.forEach((row:any) => {
      availableColumns.forEach(column => summary[column.key] = (summary[column.key] || 0) + (row[column.key]||0));
    })
    setFieldDefinitionTotalSummary(summary);
  }, [dataSource, availableColumns, setFieldDefinitionTotalSummary]);

  const onChangeDate = useCallback((date:Date) => {
    if (!date && !isISO8601(date)) {
      console.log(`[Entry] invalid entry date ${date}`);
      return;
    }
    setEntryDate(asEntryDate(date));
  }, []);

  const changeConfig = useCallback((key:string, selected:boolean) => {
    // By default selectedColumns is empty but all columns are shown as selected.
    // On deselect of the first column, set all columns as selected minus the deselected column
    if (selected === false && selectedColumns.length ===0) {
      return setSelectedColumns(
        availableColumns.map(item => item.key).filter(item => item !== key)
      );
    }
    if (selected) {
      return setSelectedColumns([...selectedColumns, key]);
    }
    return setSelectedColumns(selectedColumns.filter(item => item !== key));
  }, [availableColumns, selectedColumns, setSelectedColumns]);

  const resetConfig = useCallback(() => {
    setSelectedColumns([]);
  }, [setSelectedColumns]);

  const changeFilter = useCallback((key:string, selected:boolean) => {
    const currentFilters = selectedFilters;
    if (selected === false && currentFilters.length === 0) {
      return setSelectedFilters(
        availableFilters.map(item => item.key).filter(item => item !== key)
      );
    }
    if (selected) {
      return setSelectedFilters([...currentFilters, key]);
    }
    return setSelectedFilters(currentFilters.filter(item => item !== key));
  }, [availableFilters, selectedFilters, setSelectedFilters]);

  const resetFilter = useCallback(() => {
    setSelectedFilters([]);
  }, [setSelectedFilters]);

  const downloadToBlob = (result:any) => {
    const blob = new Blob([result], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'export.xlsx';
    link.click();
  }
  const onDownload = useCallback(async () => {
    const result = await checksService().exportDay({
      date: entryDate,
      filters: {
        columns: selectedColumns,
        managers: selectedFilters,
      }});
      downloadToBlob(result);
  }, [selectedColumns, selectedFilters, entryDate]);

  const onDownloadDateRange = useCallback(async (startDate:string, endDate:string, shops:string[]) => {
    const result = await checksService().exportDateRange({
      startDate: startDate,
      endDate: endDate,
      filters: {
        columns: selectedColumns,
        shops,
      }});
    downloadToBlob(result);
    if (panel === 'download') {
      setPanel('none');
    }
  }, [selectedColumns, setPanel, panel]);

  const closePanel = () => {
    setPanel('none');
  };

  const onCloseTag = (filterKey:string) => {
    changeFilter(filterKey, false);
  };
  return (
    <>
      <ToolBar
      title={<><Trans i18nKey="check" /> {entryDate}</>}
      actions={
        <Actions
        disabled={feedback.isWaiting}
        entryDate={entryDate}
        onShowConfig={() => setPanel('config')}
        onShowFilter={() => setPanel('filter')}
        hasFilters={hasFilters}
        onChangeDate={onChangeDate}
        onDownload={onDownload}
        onShowDownload={() => setPanel('download')}
        />
      }
      />
      {dataSource &&
        <>
        {/** FILTER TAGS */}
        {/** Not all userRoles have filters, first check availableFilters length */}
        <div>
          {hasFilters && selectedFilters.map(key => (<Tag key={key} onClose={onCloseTag} value={key} name={getName(availableFilters.find(item => item.key === key))} />))}
          {(hasFilters && selectedFilters.length > 0) && (
            <Button type="link" onClick={() => {resetFilter()}}><Trans i18nKey="Clear all"  /></Button>
          )}
        </div>
        {/** TABLE */}
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          summary={() => TableSummary(selectedColumns, availableColumns, fieldDefinitionTotalSummary)}
          scroll={{ x: true }}
          loading={feedback.isWaiting}
        />
        </>
      }
      {panel === 'config' && <Config
        onChange={changeConfig}
        selected={selectedColumns}
        items={availableColumns}
        onClose={closePanel}
        onReset={resetConfig}
        title='Columns'
      />}
      {panel === 'filter' && <Filters
        onChange={changeFilter}
        selected={selectedFilters}
        items={availableFilters}
        onClose={closePanel}
        onReset={resetFilter}
        title='Managers'
      />}
      {/** Only user roles that have filters should have the "download with date range" option  */}
      {(panel === 'download' && hasFilters) && <DownloadSelector onClose={closePanel} onDownload={onDownloadDateRange}/>}
      <Feedback />
    </>
  );

}
